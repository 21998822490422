import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import React from 'react';
import { useSelector } from 'react-redux';
import ssl from '../../../images/payment/ssl.jpeg';

const Footer = () => {
  const info = useSelector((state) => state.siteInfo?.info);
  
  return (
    // <footer className="mt-5 bg-[#5A49CA0A] ">
    <footer className="px-4 md:px-10 2xl:px-20 py-5 shadow-[0_10px_12px_rgb(0,0,0,24%)] sticky top-0 left-0 bg-[#5A49CA0A] z-10">
        {/* footer-top  */}
        <div className="sm:container mx-auto py-5 pt-10 border border-b-slate-200 border-t-0 border-l-0 border-r-0">
          <div className="md:flex">
            <div className="md:flex-1 mb-5">
              <h4 className="text-slate-600 font-medium">Quick Links</h4>
              <ul className="p-0 m-0 mt-1">
                <li className="mb-1 text-slate-600 font-normal text-sm hover:text-green-800 hover:underline"><a href="/about-us">About Us</a></li>
                <li className="mb-1 text-slate-600 font-normal text-sm hover:text-green-800 hover:underline"><a href="/privacy-policy">Privacy Policy</a></li>
                <li className="mb-1 text-slate-600 font-normal text-sm hover:text-green-800 hover:underline"><a href="/terms-and-condition">Terms & Condition</a></li>
                <li className="mb-1 text-slate-600 font-normal text-sm hover:text-green-800 hover:underline"><a href="/faq">Faq</a></li>
                <li className="mb-1 text-slate-600 font-normal text-sm hover:text-green-800 hover:underline"><a href="/news">Blog</a></li>
              </ul>
            </div>
            <div className="md:flex-1 mb-5">
              <h4 className="text-slate-600 font-medium">Contact Us</h4>
              <ul className="p-0 m-0 mt-1">
                <li className="mb-1.5 text-slate-600 font-normal text-sm w-[80%]"><LocationOnOutlinedIcon /> {info?.data?.address}</li>
                <li className="mb-1.5 text-slate-600 font-normal text-sm"><EmailOutlinedIcon /> {info?.data?.email}</li>
                <li className="mb-1.5 text-slate-600 font-normal text-sm"><LocalPhoneOutlinedIcon /> {info?.data?.phone}</li>
              </ul>
            </div>
            <div className="md:flex-1 mb-5">
              <h4 className="text-slate-600 font-medium">Payment Partner</h4>
              <img className='h-24' src={ssl} alt="Payment Details" />
            </div>
          </div>
        </div>
        {/* footer-bottom  */}
        <div className="sm:container mx-auto pt-5">
            <p className="text-center font-normal">
                <span className="text-[#006B2E] font-bold">{info?.data?.application_name}</span> - &copy; {new Date().getFullYear()}{' '}
                All Rights Reserved
            </p>
        </div>
    </footer>
  )
  };

export default Footer;
