import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ElectricBolt from '@mui/icons-material/ElectricBolt';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { app_url } from '../../../features/api';
import { addToCart } from '../../../features/cartSlice';
import { addToFavorite } from '../../../features/favoriteSlice';

const ProductsDetails = ({product}) => {
  const favoriteData = useSelector((state) => state.favorite?.favoriteItems);
  const cartData = useSelector((state) => state.cart?.cartItems);

    const [count, setCount] = useState(1);
    const [images, setImages] = useState(null);

    const [attributes, setAttributes] = useState([]);
    const [attrColor, setAttrColor] = useState([]);

    const increment = () => {
        setCount(count + 1);
    };

    let decrement = () => {
        setCount(count - 1);
    };

    if (count <= 0) {
        decrement = () => setCount(0);
    }

    const decreaseByPercentage = (number, percentage) => {
      const decreaseAmount = (percentage / 100) * number;
      const result = number - decreaseAmount;
      return result.toFixed(2);
    };

    const dispatch = useDispatch();

    const handleAddToFavorite = (data) => {
      dispatch(addToFavorite(data));
    };

    const handleAddToCart = (data) => {
      if (product?.product_attributes?.length > 0) {
        if (count > 0 && attributes?.length > 0) {
          const productAttributeNames = product?.product_attributes?.map(data => data?.name);
          const attributeNames = attributes?.map(data => data?.name);
  
          if (productAttributeNames.every(name => attributeNames.includes(name))) {
            const value = {
              data,
              attributes,
              count
            }
            dispatch(addToCart(value));
          }else{
            toast.error("Please select attributes", { position: "top-right" });
          }
        } else {
          toast.error("Please select attributes & increase the product quantity!", { position: "top-right" });
        }
      } else {
        if (count > 0) {
          const value = {
            data,
            attributes,
            count
          }
          dispatch(addToCart(value));
        } else {
          toast.error("Please increase the product quantity!", { position: "top-right" });
        }
      }
    };

    const handleAttributes = (attr, val) => {
      setAttributes((attributes) => {
        if (attributes?.map(d => d?.name).includes(attr?.name)) {
          return attributes?.filter(attribute => attribute?.name !== attr?.name);
        } else {
          return [...attributes, {name: attr?.name, value: val}];
        }
      });
    }

    const handleAttrColor = (attr, val) => {
      setAttrColor((attrColor) => {
        if (attrColor?.map(d => d?.name).includes(attr?.name)) {
          return attrColor?.filter(attribute => attribute?.name !== attr?.name);
        } else {
          return [...attrColor, {name: attr?.name, value: val}];
        }
      });
    }

    const [totalRating, setTotalRating] = useState(5);

    const total = product?.reviews.reduce((acc, review) => acc + review.rating, 0);
    const avgRating = total / product?.reviews?.length;

    const handleWarning = () =>{
      toast.warning("Maximum quantity already added!", { position: "top-right" });
    }

    const navigate = useNavigate();
    const [addedProducts, setAddedProducts] = useState([]);


    const oder = (data) => {
      const value = {
        data,
        attributes,
        count
      }
      dispatch(addToCart(value));
      setAddedProducts([...addedProducts, data.id]);
      navigate('/checkout')
    }

    return (
        <section className="my-2 px-2 sm:my-5 sm:px-4 md:px-10 2xl:px-20">
            <div className="sm:container mx-auto">
                <div className="flex flex-col lg:flex-row items-center lg:items-start gap-5 sm:gap-10 font-['Lato']">
                    <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/3">
                        <img
                            className="w-full h-96 md:h-[28rem] rounded-md mx-auto shadow-md border"
                            src={ images == null ? `${app_url}/storage/${product?.thumbnail}` : product?.images[images]?.path}
                            alt={product?.name}
                        />
                        <div className="flex mt-2.5 items-center gap-2.5 justify-between">
                            <div
                                onMouseEnter={() => setImages(null)}
                                className="cursor-pointer"
                            >
                                <img
                                    className="w-[60px] h-[60px] sm:w-[100px] sm:h-[100px] rounded-md shadow-md"
                                    src={`${app_url}/storage/${product?.thumbnail}`}
                                    alt=""
                                />
                            </div>
                            {product?.images?.map((data, key) => (
                                <div
                                    onMouseEnter={() => setImages(key)}
                                    key={key}
                                    className="cursor-pointer"
                                >
                                    <img
                                        className="w-[60px] h-[60px] sm:w-[100px] sm:h-[100px] rounded-md shadow-md"
                                        src={data?.path}
                                        alt=""
                                    />
                                </div>
                              ))}
                        </div>
                    </div>
                    {/* <div className="lg:w-1/3 xl:w-1/3">
                        <img
                            className="w-full h-96 rounded-md mx-auto shadow-sm"
                            src={`${app_url}/storage/${product?.thumbnail}`}
                            alt={product?.name}
                        />
                        <div className="flex mt-2.5 items-center gap-2.5 justify-between">
                            {product?.images?.map((data, key) => (
                                <div
                                    onMouseEnter={() => setImages(key)}
                                    key={key}
                                    className="cursor-pointer"
                                >
                                    <img
                                        className="w-[100px] h-[100px] rounded-md shadow-md"
                                        src={data?.path}
                                        alt=""
                                    />
                                </div>
                            ))}
                        </div>
                    </div> */}
                    <div className="lg:w-2/3 xl:w-2/3">
                      {
                        product?.sku && 
                        <small className="text-xs">SKU: {product?.sku}</small>
                      }
                        <h1 className="font-bold font-['Poppins'] mt-1">
                            {product?.name}
                        </h1>
                        <div className="flex items-center justify-between gap-3 md:gap-5 mt-2">
                            <div className="flex items-center gap-3 md:gap-5">
                                  {
                                    product?.review > 0 && <>
                                    <div className="text-xs flex">
                                      <small className="mr-2 text-sm font-bold">Rating: {avgRating}</small>

                                      {Array.from({ length: totalRating }).map((_, index) => (
                                        <span key={index}>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className={`w-5 h-5 cursor-pointer ${
                                              index < avgRating ? 'text-yellow-500' : 'text-gray-400'
                                            }`}
                                          >
                                            <path
                                              fillRule="evenodd"
                                              d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                                              clipRule="evenodd"
                                            />
                                          </svg>
                                        </span>
                                      ))}
                                    </div>
                                    </>
                                  }
                                <div className="text-xs">
                                    <span className="icon-tick" />
                                    <small className="ml-2 text-sm font-bold">Stock: {product?.stock} </small>
                                </div>
                            </div>

                            {/* wishlist area  */}
                            {favoriteData?.map(item => item?.id).includes(product?.id) ? (
                            <div className="text-xs flex align-middle text-gray-600">
                              <button
                                type="button"
                                className="text-lg border-none rounded-md py-1 px-1 text-red-600"
                                onClick={() => handleAddToFavorite(product)}
                              >
                                <FavoriteIcon />
                                  <small className="ml-2 text-sm font-normal hidden sm:inline-block">Remove from wishlist</small>
                              </button>
                            </div>
                          ) : (
                          <div className="text-xs flex align-middle text-gray-600">
                            <button
                                type="button"
                                className="text-lg border-none rounded-md py-1 px-1 text-gray-500 hover:text-red-600"
                                onClick={() => handleAddToFavorite(product)}
                              >
                                <FavoriteBorderIcon />
                                <small className="ml-2 text-sm font-normal hidden sm:inline-block">Add to wishlist</small>
                            </button>
                            </div>
                          )}
                      {/* wishlist area  */}
                        </div>
                        <div className="flex flex-wrap gap-5 justify-between items-center mt-5">
                            <div className="flex flex-wrap items-center gap-5 font-['Poppins']">
                                <h1 className="font-bold text-xl text-[#006B2E]">
                                {
                                  product?.discount ? 
                                  <>{product?.discount_type == 'percentage' ? <>{decreaseByPercentage(product?.price, product?.discount)} BDT</> : <>{product?.price - product?.discount} BDT </>}</> :
                                  <>{product?.price} BDT</> 
                                }
                                </h1>
                                
                                <p className="line-through text-red-500 text-sm">BDT {product?.price}</p>
                                {
                                  product?.discount && 
                                  <small className="bg-[#006B2E] text-white py-1 px-2 rounded-md text-xs">
                                      {product?.discount} {product?.discount_type === "percentage" ? '%' : 'Tk discount'} 
                                  </small>
                                }
                            </div>
                            <div>
                                {/* <img className="w-[110px] md:w-[150px]" src={logo} alt="" /> */}
                            </div>
                        </div>
                        <div className="border-b-2 border-gray-300 my-5" />
                        <h1 className="font-bold mb-4 capitalize">Short Description:</h1>
                        <p className="text-sm">
                            {product?.description}
                        </p>
                        {
                          product?.product_attributes?.map((attr, i)=>(
                            <div key={i} className="mt-6 mb-6">
                              <h1 className="font-bold mb-4 capitalize">{attr?.name}</h1>
                              <div className="flex flex-wrap items-center gap-2.5">
                                  {
                                    attr?.value?.map((val, i) => (
                                      <>
                                      {
                                      typeof val === 'object' ? 
                                      <button
                                        key={i}
                                        type="button"
                                        className={`border border-[rgb(255_234_222)] text-white hover:border-green-700 px-5 py-1.5 rounded-md ${attributes?.map(d => d?.value?.title).includes(val?.title) ? `border-2 border-green-500 bg-green-100` : ''}`}
                                        style={{ backgroundColor: `${val?.value}` }}
                                        onClick={(i) => handleAttributes(attr, val)}
                                      >
                                        {val?.title}
                                      </button>
                                      : 
                                      <button
                                        key={i}
                                        type="button"
                                        className={`border border-[rgb(255_234_222)] hover:border-green-700 px-5 py-1.5 rounded-md ${attributes?.map(d => d?.value).includes(val) ? `border-green-500 bg-green-100` : ''}`}
                                        onClick={(i) => handleAttributes(attr, val)}
                                      >
                                        {val}
                                      </button>
                                      }
                                      </>
                                    ))
                                  }
                                  
                              </div>
                            </div>
                          ))
                        }
                        
                        <h1 className="font-base mt-4">Weight: {product?.minimum_quantity}</h1>
                        <div className="flex flex-wrap gap-2.5 md:gap-5 items-center my-5 lg:my-10">
                            <div className="flex items-center border border-[rgb(216_216_216)] gap-5 md:gap-10 rounded-md py-2 px-3">
                                <button onClick={decrement} type="button">
                                    <span className="icon-minus" />
                                </button>
                                <p>{count}</p>
                                {product?.stock > count ?
                                <button onClick={increment} type="button" className=" hover:text-red-600">
                                    <span className="icon-plus" />
                                </button> : 
                                <button 
                                onClick={ ()=>handleWarning() } type="button">
                                    <span className="icon-plus" />
                                </button>
                                }
                                
                            </div>
                            <div className="flex items-center gap-2.5">
                                <button
                                    type="button"
                                    className="px-5 md:px-8 py-2 border border-green-200 hover:bg-[#006B2E] hover:text-white text-sm rounded-md shadow-lg"
                                    onClick={() => oder(product)}
                                >
                                    <ElectricBolt /> BUY NOW
                                </button>
                                {cartData?.map(item => item?.data?.id).includes(product?.id) ? (
                                  <button
                                      type="button"
                                      disabled
                                      className="px-5 md:px-8 py-2 border border-[rgb(216_216_216)] text-sm text-white rounded-md bg-[#5d7166] "
                                  >
                                      Already Added
                                  </button>
                                    ) : (
                                      <>
                                      {
                                        product?.stock < 1 ? (
                                          <button
                                          type="button"
                                          disabled
                                          className="px-5 md:px-8 py-2 border border-red-400 text-sm text-red-500 rounded-md bg-red-200"
                                      >
                                         Stock Out
                                      </button>
                                        ) : (
                                          <button
                                          type="button"
                                          className="px-5 md:px-8 py-2 border border-[rgb(216_216_216)] text-sm text-white rounded-md bg-[#006B2E] hover:bg-[#1c4f33]"
                                          onClick={() => handleAddToCart(product)}
                                      >
                                          <AddShoppingCartIcon /> ADD TO CART
                                      </button>
                                        )
                                      }
                                      </>
                                    )}
                            </div>
                        </div>
                        <h1 className="font-bold mb-4 capitalize">Category: {product?.category?.name}</h1>
                        {
                          product?.brand_id > 0 && 
                        <h1 className="font-bold mb-4 capitalize">Brand: {product?.brand?.name}</h1>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProductsDetails;
