import React from 'react';

const Success = ({ ref, data }) => {
  return (
    <div ref={ref} className="py-4 border border-green-500 text-center rounded mb-4">
      <h2 className="text-3xl text-slate-700 font-semibold mb-1">Congratulations</h2>
      <p>Your order has been successfully received!</p>
      <p>Stay with us.</p>
    </div>
  );
};

export default Success;
