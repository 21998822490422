import React from 'react'

function SkeletonCard() {
  return (
    <>
    <div
        className="w-[47%] sm:w-[47%] lg:w-64 rounded-md shadow-[0px_3px_3px_0_rgb(0,0,0,.15)] hover:shadow-[rgba(0,0,0,0.24)_0px_3px_8px] z-10">
        <div className='relative'>
            {/* img  */}
            <div className="w-full h-64 rounded-md bg-slate-400"></div>
        </div>
        <div className="p-4 flex flex-col gap-1.5">
            {/* title */}
            <div className="h-2 font-normal text-md bg-slate-500 rounded"></div>

            <div className="flex gap-3 justify-between">
              <div className="h-3 bg-slate-400 rounded w-20"></div>
              <div className="h-3 bg-slate-400 rounded w-20"></div>
            </div>

            <div className="flex gap-3">
              <div className="h-7 bg-slate-500 rounded flex-1"></div>
              <div className="h-7 bg-slate-500 rounded w-8"></div>
            </div>
            
        </div>
    </div>
    </>
  )
}

export default SkeletonCard
