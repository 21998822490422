/* eslint-disable import/no-unresolved */
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Categories from './components/Categories/Categories/Categories';
import MyOrders from './components/Customer/MyOrders';
import OrderDetails from './components/Customer/OrderDetails';
import OrderSuccess from './components/Customer/OrderSuccess';
import Profile from './components/Customer/Profile';
import Settings from './components/Customer/Settings';
import About from './components/Extra/About';
import Blog from './components/Extra/Blog';
import BlogDetails from './components/Extra/BlogDetails';
import Faq from './components/Extra/Faq';
import Privacy from './components/Extra/Privacy';
import Terms from './components/Extra/Terms';
import Favorite from './components/Favorite/Favorite';
import Home from './components/Home/Home/Home';
import NotFound from './components/NotFound/NotFound';
import CancelOrder from './components/Orders/CancelOrder';
import Cart from './components/Orders/Cart';
import CheckOut from './components/Orders/CheckOut';
import ProductView from './components/Products/ProductView/ProductView';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SearchProducts from './components/Search/Search/SearchProducts';
import Footer from './components/Shared/Footer/Footer';
import Navbar from './components/Shared/Navbar/Navbar';
import TopNavbar from './components/Shared/TopNavbar/TopNavbar';
import Shop from './components/Shop/Shop';
import TrackOrder from './components/TrackOrder/TrackOrder';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import VerifyPhone from './components/auth/VerifyPhone';
import { initFacebookPixel } from "./features/facebookPixel";

const App = () =>{ 
  const siteInfo = useSelector((state) => state?.siteInfo?.info?.marketing);

  useEffect(() => {
    if (siteInfo?.g_status) {
      // Initialize Google Analytics with the fetched tracking ID
      ReactGA.initialize(siteInfo?.g_analytics);

      // Optionally track the initial pageview
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    }
  }, [siteInfo?.id, window.location]);

  useEffect(() => {
    if (siteInfo?.f_status) {
      initFacebookPixel(siteInfo?.f_pixel);
    }
  }, [siteInfo?.id]);

  return (
    <Router>
        <ScrollToTop>
          <ToastContainer />
            <TopNavbar />
            <Navbar />
            <Routes>
                <Route index path="/" element={<Home />} />
                <Route path="/products" element={<Shop />} />
                <Route path="/product-view/:slug" element={<ProductView />} />
                <Route path="/search/:value" element={<SearchProducts />} />
                <Route path="/categories/:value" element={<Categories />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/favorite" element={<Favorite />} />
                <Route path="/checkout" element={<CheckOut />} />
                <Route path="/track" element={<TrackOrder />} />
                <Route path="/cancel-order" element={<CancelOrder />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
                {/* <Route path="/change-password" element={<ChangePassword />} /> */}
                {/* START: auth routes  */}
                <Route path="/verify-phone" element={<VerifyPhone />} />
                <Route path="/customer/profile" element={<Profile />} />
                <Route path="/customer/orders" element={<MyOrders />} />
                <Route path="/customer/:orderId/orders-view" element={<OrderDetails />} />
                <Route path="/customer/:orderId/order-success" element={<OrderSuccess />} />
                <Route path="/customer/settings" element={<Settings />} />
                {/* END: auth routes  */}
                <Route path="/faq" element={<Faq />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/terms-and-condition" element={<Terms />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/news" element={<Blog />} />
                <Route path="/news/:slug/view" element={<BlogDetails />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
        </ScrollToTop>
    </Router>
);
};

export default App;
