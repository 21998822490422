import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import {
  TEModal,
  TEModalBody,
  TEModalContent,
  TEModalDialog,
  TEModalFooter,
  TEModalHeader,
  TERipple,
} from "tw-elements-react";
import { setNHeaders, url } from '../../../features/api';

const SettingsComponent = () => {
  const auth = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setUpdateShowModal] = useState(false);

  const [info, setInfo] = useState({
    current_password: "",
    password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({
    current_password: '',
    password: '',
    confirm_password: '',
  });

  const [addressInfo, setAddressInfo] = useState({
    title: "",
    address: "",
  });

  const [addressTitle, setAddressTitle] = useState('');
  const [address, setAddress] = useState('');

  const [errorsAddress, setErrorsAddress] = useState({
    title: "",
    address: "",
  });
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [del, setDel] = useState(1);

  const validateForm = () => {
    const newErrors = {
      current_password: info.current_password ? '' : 'Current Password is required.',
      password: info.password ? '' : 'New Password is required.',
      confirm_password:
            info.confirm_password === info.password
                ? ''
                : 'Confirm Passwords do not match.',
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };

  const validateFormAddress = () => {
    const othersErrors = {
      title: addressInfo.title ? '' : 'Title is required.',
      address: addressInfo.address ? '' : 'Address is required.',
    };

    setErrorsAddress(othersErrors);
    return Object.values(othersErrors).every((error) => error === '');
  };

  const validateUpdateAddressForm = () => {
    const newErrors = {
      address: address ? '' : 'Address is required.',
    };

    setErrorsAddress(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };


  const handleSubmitSecurity = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Make a POST request
      axios.post(`${url}/update-password`, info, setNHeaders())
      .then(response => {
        if(response?.status === 201){
          toast.error(response?.data?.message, {
            position: "top-right",
          });
        }else{
          setInfo({
            current_password: "",
            password: "",
            confirm_password: "",
          })
          toast.success(response?.data?.message, {
            position: "top-right",
          });
        }
    
      })
      .catch(err => {
        toast.error('Something went wrong!', {
          position: "bottom-right",
        });
      });
    } else {
      toast.error("Form contains errors!", {
        position: "bottom-right",
      });
    }
  }


  const handleSubmitAddress = (e) => {
    e.preventDefault();
    if (validateFormAddress()) {
      // Make a POST request
      axios.post(`${url}/profile-add-address`, addressInfo, setNHeaders())
      .then(response => {
        setShowModal(false);
        setAddressInfo({
          title: "",
          address: "",
        })
        setDel(del+1)
        toast.success(response?.data?.message, {
          position: "top-right",
        });
      })
      .catch(err => {
        toast.error(err?.response?.data?.errors?.title[0], {
          position: "bottom-right",
        });
      });
    } else {
      toast.error("Form contains errors!", {
        position: "bottom-right",
      });
    }
  }

  const handleDeleteAddress = (data) => {
    axios.post(`${url}/delete-address`, data, setNHeaders())
    .then(response => {
      setDel(del+1)
      toast.error(response?.data?.message, {
        position: "top-right",
      });
    })
    .catch(err => {
      toast.error(err, {
        position: "bottom-right",
      });
    });
  }

  const handleUpdateModal = (data) => {
    setUpdateShowModal(true);
    setAddressTitle(data.title)
    setAddress(data.address)
  }

  const handleUpdateAddress = (e) => {
    e.preventDefault();
    if (validateUpdateAddressForm()) {
      const payload = {
        title: addressTitle,
        address: address
      }
      axios.post(`${url}/update-address`, payload, setNHeaders())
      .then(response => {
        setUpdateShowModal(false);
        setDel(del+1)
        toast.info(response?.data?.message, {
          position: "top-right",
        });
      })
      .catch(err => {
        toast.error(err?.response?.data?.errors?.title[0], {
          position: "bottom-right",
        });
      });
    } else {
      toast.error("Form contains errors!", {
        position: "bottom-right",
      });
    }
  }

  useEffect(() => {
    // Make a GET request using Axios
     axios.get(`${url}/address-data`, setNHeaders())
      .then(response => {
        setData(response?.data);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [del]);


  return (
    <>
    {/* Address */}
    <div className="bg-slate-50 shadow-md px-4 py-4 rounded">
        <div className="space-y-12">
          <div className="pb-2">
            <h2 className="text-xl font-normal leading-7 text-green-700">My Address</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              This information can be your delivery address.
            </p>
            <div className="mt-4 w-full">
            <div className="py-4 flex flex-wrap gap-2">
              {/* info error  */}
              { error ? <span style={{ color: 'red' }}>{error}</span> : ''}
              {/* info loading  */}
              {
                loading ? 
                <>
                {/* info container  */}
                <div className="border border-slate-300 px-4 py-2 w-52 min-h-24 rounded">
                  <div className="flex items-center justify-between">
                    <h3 className="mt-1 w-full rounded-md h-6 m-1 bg-slate-500"></h3>
                    <div className="mb-[0.125rem] cursor-pointer min-h-[1.5rem] pl-[1.5rem]">
                      <div className="w-4 h-4 m-1 bg-slate-600 rounded-sm"></div>
                      <div className="w-4 h-4 m-1 bg-red-400 rounded-sm"></div>
                    </div>
                  </div>
                  <p className="mt-1 w-full rounded-md h-6 m-1 bg-slate-400"></p>
                </div>
                </> : <>
                {/* info container  */}
                {
                  data === null ? '' : <>
                  {
                    data?.data?.profile?.address.slice(0, 3).map((res, index) => (
                    <div key={index} className="border border-slate-300 px-4 py-2 w-52 min-h-24 rounded">
                      <div className="flex items-center justify-between">
                        <h3 className="font-medium text-slate-700">{res.title}</h3>
                        <div className="mb-[0.125rem] cursor-pointer min-h-[1.5rem] pl-[1.5rem]">
                          <EditLocationAltIcon onClick={() => handleUpdateModal(res)} style={{ fontSize: '20px' }}/>
                          <DeleteOutlineIcon onClick={()=>handleDeleteAddress(res)} style={{ fontSize: '20px', color: 'red' }}/>
                        </div>
                      </div>
                      <p className="mt-1 text-slate-500 text-sm">{res.address}</p>
                    </div>
                    ))
                  }
                  </>
                }
                </>
              }
              {/* add new  */}
              {
                data?.data?.profile?.address?.length >= 3 ? <>
                <div className="border border-dashed border-slate-50 bg-slate-300 cursor-pointer px-4 w-52 h-24 text-slate-500 flex items-center text-center justify-center rounded transition duration-400">
                <CloseIcon style={{ color: 'red' }} /> Can Add max 3 address
              </div>
                </> : 
                <>
                <div onClick={() => setShowModal(true)} className="border border-dashed border-slate-300 cursor-pointer hover:border-green-700 hover:bg-slate-50 px-4 w-52 h-24 text-slate-500 flex items-center text-center justify-center rounded transition duration-400">
                  <AddIcon /> Add New Address
                </div>
                </>
              }
            </div>
            </div>
          </div>
        </div>
    </div>

    {/* <!-- Address Modal --> */}
    <TEModal show={showModal} setShow={setShowModal}>
        <TEModalDialog>
          <TEModalContent>
            <form onSubmit={handleSubmitAddress}>
            <TEModalHeader>
              {/* <!--Modal title--> */}
              <h5 className="text-xl font-medium leading-normal text-slate-500">
                Add New Address
              </h5>
              {/* <!--Close button--> */}
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => setShowModal(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>
            {/* <!--Modal body--> */}
            <TEModalBody>
              <div className="mb-1">
                <label htmlFor="title" className="block text-sm font-normal leading-6 text-slate-800 mt-2">
                  Title *
                </label>
                <input
                  type="text"
                  name="title"
                  placeholder='Home, Office, Others ...'
                  value={addressInfo.title}
                  onChange={(e) => setAddressInfo({ ...addressInfo, title: e.target.value})}
                  className={`${ errorsAddress?.title ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                />
                <span className="text-sm text-red-600">{errorsAddress.title}</span>
              </div>
              <div className="mb-1">
                <label htmlFor="address" className="block text-sm font-normal leading-6 text-slate-800 mt-2">
                  Address *
                </label>
                <input
                  type="text"
                  name="address"
                  placeholder='Address ...'
                  value={addressInfo.address}
                  onChange={(e) => setAddressInfo({ ...addressInfo, address: e.target.value})}
                  className={`${ errorsAddress?.address ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                />
                <span className="text-sm text-red-600">{errorsAddress.address}</span>
              </div>
            </TEModalBody>
            <TEModalFooter>
              <TERipple rippleColor="light">
                <button
                  type="button"
                  className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-green-700 transition duration-150 ease-in-out hover:bg-green-accent-100 focus:bg-green-accent-100 focus:outline-none focus:ring-0 active:bg-green-accent-200"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </TERipple>
              <TERipple rippleColor="light">
                <button
                  type="submit"
                  className="ml-1 inline-block rounded bg-green-700 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-[rgb(61,46,60)]"
                >
                  Save changes
                </button>
              </TERipple>
            </TEModalFooter>
            </form>
          </TEModalContent>
        </TEModalDialog>
    </TEModal>

    {/* <!--Update Address Modal --> */}
    <TEModal show={showUpdateModal} setShow={setUpdateShowModal}>
        <TEModalDialog>
          <TEModalContent>
            <form onSubmit={handleUpdateAddress}>
            <TEModalHeader>
              {/* <!--Modal title--> */}
              <h5 className="text-xl font-medium leading-normal text-slate-500">
                Update Address
              </h5>
              {/* <!--Close button--> */}
              <button
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                onClick={() => setUpdateShowModal(false)}
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </TEModalHeader>
            {/* <!--Modal body--> */}
            <TEModalBody>
              
              <div className="mb-1">
                <label htmlFor="address" className="block text-sm font-normal leading-6 text-slate-800 mt-2">
                  Address *
                </label>
                <input
                  type="text"
                  name="address"
                  placeholder='Address ...'
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className={`${ errorsAddress?.address ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
                />
                <span className="text-sm text-red-600">{errorsAddress.address}</span>
              </div>
            </TEModalBody>
            <TEModalFooter>
              <TERipple rippleColor="light">
                <button
                  type="button"
                  className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-green-700 transition duration-150 ease-in-out hover:bg-green-accent-100 focus:bg-green-accent-100 focus:outline-none focus:ring-0 active:bg-green-accent-200"
                  onClick={() => setUpdateShowModal(false)}
                >
                  Close
                </button>
              </TERipple>
              <TERipple rippleColor="light">
                <button
                  type="submit"
                  className="ml-1 inline-block rounded bg-green-700 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-[rgb(61,46,60)]"
                >
                  Update
                </button>
              </TERipple>
            </TEModalFooter>
            </form>
          </TEModalContent>
        </TEModalDialog>
    </TEModal>



    {/* Security */}
    {/* <div className="bg-slate-50 shadow-md px-4 py-4 rounded mt-10">
      <form onSubmit={handleSubmitSecurity}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-xl font-normal leading-7 text-green-700">Security</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
            Change password
            </p>
            <div className="mt-4">
              <label htmlFor="current_password" className="block text-sm font-normal leading-6 text-slate-800">
                Current Password
              </label>
              <input
                type="password"
                name="current_password"
                placeholder='Type your Current Password'
                value={info.current_password}
                onChange={(e) => setInfo({ ...info, current_password: e.target.value})}
                className={`${ errors?.current_password ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
              />
              <span className="text-sm text-red-600">{errors.current_password}</span>
            </div>
            <div className="mt-4">
              <label htmlFor="username" className="block text-sm font-normal leading-6 text-slate-800">
                New Password
              </label>
              <input
                type="password"
                name="name"
                placeholder='Type New Password'
                value={info.password}
                onChange={(e) => setInfo({ ...info, password: e.target.value})}
                className={`${ errors?.password ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
              />
              <span className="text-sm text-red-600">{errors.password}</span>
            </div>
            <div className="mt-4">
              <label htmlFor="username" className="block text-sm font-normal leading-6 text-slate-800">
                Confirm Password
              </label>
              <input
                type="password"
                name="name"
                placeholder='Type Confirm Password'
                value={info.confirm_password}
                onChange={(e) => setInfo({ ...info, confirm_password: e.target.value})}
                className={`${ errors?.confirm_password ? `border-red-600` : `border-[rgb(212_212_212)]` } border w-full mt-2 rounded-md h-10 px-2`}
              />
              <span className="text-sm text-red-600">{errors.confirm_password}</span>
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            className="rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[rgb(61,46,60)] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[rgb(73_53_72)]"
          >
            Save Change
          </button>
        </div>
      </form>
    </div> */}
    </>
  )
}

export default SettingsComponent
