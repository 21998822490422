import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autoplay, EffectCoverflow, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { fetchSlider } from '../../../features/topSliderSlice';

const TopBanner = () => {
  const slider = useSelector((state) => state.topSlider?.slider?.data);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(()=>{
      setLoading(false)
    }, 500)
  
    return () => clearTimeout(timer);
  }, [])

  useEffect(() => {
    dispatch(fetchSlider());
  }, [dispatch])

    return (
        <section className="mb-0 mt-8 sm:mt-10 sm:mb-10 px-4 md:px-10 2xl:px-20">
            <div className="mx-auto">
              {
                loading ? (
                  <div className="w-full p-12 pb-12 rounded bg-slate-600 h-[188px] sm:h-[280px] md:h-[340px] lg:h-[360px] xl:h-[400px] 2xl:h-[420px]">
                    <div className="top-8 left-6 sm:top-12 sm:left-12 md:top-16 md:left-16 lg:top-20 lg:left-20 2xl:top-24 2xl:left-24">
                      <div className="w-1/3 h-8 mt-0 sm:mt-6 bg-slate-500 rounded"></div>
                      <div className="w-2/3 h-10 mt-2 sm:mt-6 bg-slate-500 rounded"></div>
                      <div className="w-24 md:w-48 h-12 mt-2 sm:mt-6 bg-slate-400 rounded"></div>
                    </div>
                  </div>
                ) : (
                  <>
                  {
                    slider?.length > 0 ? (
                      <>
                      <Swiper
                          effect="coverflow"
                          grabCursor
                          centeredSlides
                          slidesPerView="auto"
                          coverflowEffect={{
                              rotate: 0,
                              stretch: 0,
                              depth: 100,
                              modifier: 1,
                              slideShadows: true,
                          }}
                          pagination={{
                              clickable: true,
                          }}
                          spaceBetween={20}
                          autoplay={{
                              delay: 4500,
                              disableOnInteraction: false,
                          }}
                          loop
                          modules={[EffectCoverflow, Pagination, Autoplay]}
                          className="w-full pb-12"
                      >
                          {slider.map((data) => (
                              <SwiperSlide key={data.id} className="w-full h-[188px] sm:h-[280px] md:h-[340px] lg:h-[360px] xl:h-[400px] 2xl:h-[420px]">
                                  <img
                                      className="w-full block h-full rounded-md object-cover relative"
                                      src={data?.image}
                                      alt={data?.title}
                                  />
                                  <div className="absolute top-4 left-6 sm:top-8 sm:left-12 md:top-16 md:left-16 lg:top-20 lg:left-20 2xl:top-24 2xl:left-24">
                                    <h3 className='text-md sm:text-xl w-2/3 mb-0 sm:mb-2 xl:mb-4 mt-0 sm:mt-2'>{data?.sub_title}</h3>
                                    <h2 className="text-xl sm:text-2xl md:text-5xl w-2/3 mb-4 sm:mb-6 xl:mb-12 font-semibold font-['sans-serif']">{data?.title}</h2>
                                    <a href={data?.url} className="py-1 px-2 sm:py-2 sm:px-4 rounded text-white text-sm sm:text-base uppercase bg-gradient-to-r from-[#006B2E] to-[#006B2E]"
                                          >{data?.btn_name}</a>
                                  </div>
                              </SwiperSlide>
                          ))}
                      </Swiper>
                      </>
                    ) : (
                      <div className="w-full p-12 pb-12 h-[380px] rounded bg-slate-600 flex justify-center items-center">
                        <div className='text-center'>
                        <h2 className="font-semibold text-3xl mb-3 font-[sans-serif] text-slate-200"> No Slider Yet!</h2>
                        <p className="text-sm text-slate-200">If you are admin? please add from admin panel</p>
                        </div>
                      </div>
                    )
                  }
                  </>
                )
              }
                
            </div>
        </section>
    );
};
export default TopBanner;
