import { useAutoAnimate } from '@formkit/auto-animate/react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getDiscountProducts } from '../../../features/productsSlice';
import { useGetAllSalesProductsQuery, useGetProductQuery } from '../../../features/salesProducts';
import ProductCard from '../CommonCard/ProductCard';

const OnSale = () => {
    const { data, error, isLoading} = useGetAllSalesProductsQuery();
    const { data: singleProductData } = useGetProductQuery('iphone');
    const salesProducts = data?.products?.slice(0, 10);
    const cartData = useSelector((state) => state.cart);
    const favoriteData = useSelector((state) => state.favorite);
    
    const [animationParent] = useAutoAnimate();
    
    const products = useSelector((state) => state.products);
    const discountProducts = products.discountProducts?.data?.products?.data?.slice(0, 10);

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getDiscountProducts());
    }, [dispatch])

    return (
        <section className="my-4 sm:my-10 px-2 sm:px-4 md:px-10 2xl:px-20">
            <div className="sm:container mx-auto">
                <div className='flex justify-between align-middle'>
                  <h1 className="font-semibold text-2xl text-gray-700 font-['sans-serif']">On Sale Products</h1>
                  <Link className='rounded-md py-1 px-4 bg-slate-200 border border-slate-300 text-green-700 hover:text-gray-700' to={'/categories/sales-products'}>See All <ArrowRightAltIcon/></Link>

                </div>
                <div ref={animationParent} className={`mt-10 flex flex-wrap  gap-2 sm:gap-5 ${discountProducts?.length > 5 ? 'items-center justify-center 2xl:justify-between' : ''}`}>
                    <ProductCard products={discountProducts} error={error} isLoading={isLoading} cartData={cartData.cartItems} favoriteData={favoriteData.favoriteItems} />
                </div>
            </div>
        </section>
    );
};

export default OnSale;
