import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { app_url } from '../../features/api';
import { addToCart, clearCart, decreaseCart, getTotals, removeFromCart } from '../../features/cartSlice';
import empty from '../../images/Cart/empty.png';
import SkeletonCartItem from '../Shared/CommonCard/SkeletonCartItem';

const Cart = () => {
    const cartData = useSelector((state) => state.cart);
    
    const [isChecked, setIsChecked] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [attributes, setAttributes] = useState([]);
    const [count, setCount] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleAddToCart = (data) => {
      const value = {
        data,
        attributes,
        count
      }
      dispatch(addToCart(value));
    };

    const handleSelectAll = () => {
      setIsChecked(!isChecked);
      if (!isChecked) {
        const allIds = cartData?.cartItems?.map(item => item.data.id);
        setSelectedIds(allIds);
      }else{
        setSelectedIds([]);
      }
    };

    const handleCheckboxChange = id => {
      if (selectedIds.includes(id)) {
        setSelectedIds(selectedIds.filter(itemId => itemId !== id));
      } else {
        setSelectedIds([...selectedIds, id]);
      }
    };

    const handleClearCart = () => {
      dispatch(clearCart());
      setIsChecked(!isChecked);
    };
    
    const handleDecreaseCart = (data) => {
      dispatch(decreaseCart(data));
    };

    const handleRemoveFromCart = () => {
      dispatch(removeFromCart({ ids: selectedIds }));
    };

    useEffect(() => {
      dispatch(getTotals());
      const timer = setTimeout(()=>{
        setLoading(false)
      }, 500)
    
      return () => clearTimeout(timer);
    }, [cartData, dispatch]);

    const decreaseByPercentage = (number, percentage) => {
      const decreaseAmount = (percentage / 100) * number;
      const result = number - decreaseAmount;
      return result.toFixed(2);
    };

    const ratingPerProduct = (reviews) => {
      const total = reviews?.reduce((acc, review) => acc + review.rating, 0);
      const avgRating = total / reviews?.length;
      return avgRating;
    };

    const handleWarning = () =>{
      toast.warning("Maximum quantity already added!", { position: "top-right" });
    }
    // console.log("cartData?.cartItems", cartData)
        
    return (
        <section className="my-10 px-4 md:px-10 2xl:px-20 min-h-screen">
            <div className="sm:container mx-auto">
                <div className="flex flex-col xl:flex-row gap-10">
                    <div className="w-full xl:w-3/4">
                        <div className="border border-[rgb(212_212_212)] px-2 md:px-5 py-2.5 rounded-md flex items-center justify-between">
                            <div>
                                <input
                                    type="checkbox"
                                    name="checkout"
                                    className="hue-rotate-[150deg] scale-125"
                                    checked={cartData?.cartItems?.length === selectedIds?.length && isChecked}
                                    onChange={handleSelectAll}
                                />
                                <label
                                    htmlFor="checkout"
                                    className="pl-2 md:pl-4 text-sm md:text-base"
                                >
                                    Select All
                                </label>
                            </div>
                            <div className="flex items-center gap-2 md:gap-6">
                                <p className="border-r-2 pr-2 md:pr-6 border-[rgb(237_237_237)] text-sm md:text-base">
                                    Update Cart 
                                </p>
                                {
                                  selectedIds?.length > 0 ?
                                  cartData?.cartItems?.length === selectedIds?.length ?
                                  (
                                  <button onClick={() => handleClearCart()} className="text-red-600 cursor-pointer text-sm md:text-base">Clear Cart</button>
                                  ) : (
                                    <button onClick={() => handleRemoveFromCart()} className="text-red-600 cursor-pointer text-sm md:text-base">Remove</button>
                                  )
                                  :
                                  <p className="text-red-300 text-sm md:text-base">Remove</p>
                                }

                                
                            </div>
                        </div>

                        {cartData?.cartItems?.length === 0 ? (
                        <div className='flex items-center justify-center w-full h-72'>
                          <div className='text-center'>
                            <img className='w-28 m-auto' src={empty} alt="empty-cart" />
                            <p className='text-md text-gray-600'>Cart is empty! <Link className='underline cursor-pointer hover:text-green-700' to="/">Back to Shopping</Link></p>

                          </div>
                        </div>
                        ):(
                          <>
                          {
                            loading ? (
                              Array(cartData?.cartItems?.length).fill(0).map((products, index)=><SkeletonCartItem key={index} />)
                            ) : (
                              <>
                              {cartData?.cartItems?.map((product, index) => (
                            <div
                                className="mt-4 px-2 md:px-5 py-3 flex flex-wrap gap-5 justify-between shadow-[0px_3px_3px_0_rgb(0,0,0,6%)] hover:shadow-[rgba(0,0,0,0.24)_0px_3px_8px] rounded-md"
                                key={index}
                            >
                                <div className="flex flex-wrap gap-4">
                                    <div className="flex justify-center">
                                        <input
                                            type="checkbox"
                                            name="checkout"
                                            checked={selectedIds.includes(product?.data.id)}
                                            onChange={() => handleCheckboxChange(product?.data.id)}
                                            className="hue-rotate-[150deg] scale-125"
                                        />
                                    </div>
                                    <div className="w-24">
                                        <img
                                            className="w-full h-20 sm:h-28 rounded-md border border-slate-200"
                                            src={`${app_url}/storage/${product?.data?.thumbnail}`}
                                            alt={product?.data?.name}
                                        />
                                    </div>
                                    <div className="flex flex-col justify-between">
                                        <div>
                                            <Link to={`/product-view/${product?.data?.slug}`} className="font-semibold text-gray-700  text-sm lg:text-base">
                                                {product?.data?.name}
                                            </Link>
                                            <p className="font-semibold mt-2 text-gray-500 text-sm">
                                              Price: {
                                                      product?.data?.discount ? 
                                                      <>{product?.data?.discount_type == 'percentage' ? 
                                                        <>{decreaseByPercentage(product?.data?.price, product?.data?.discount)} BDT <span className='ml-3 text-red-600 line-through'>{product?.data?.price} BDT</span> </> : 
                                                        <>{product?.data?.price - product?.data?.discount} BDT <span className='ml-3 text-red-600 line-through'>{product?.data?.price} BDT</span></>
                                                      }</> :
                                                      <>{product?.data?.price} BDT</>
                                                    }
                                            </p>
                                            <small className="text-xs text-gray-500">
                                              Discount: { product?.data?.discount ? <>{ (product?.data?.discount)} {product?.data?.discount_type == 'percentage' ? '%' : 'TK'}</>  : 0 }
                                            </small>

                                            
                                        </div>
                                        {/* rating  */}
                                        <div className='flex items-center'>
                                         {product?.data?.review > 0 ? <>
                                          <svg className="w-5 h-5 text-yellow-400 mr-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                                              <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                                          </svg>
                                          <p className='text-gray-400 text-sm'>{ratingPerProduct(product?.data?.reviews)} out of 5</p>
                                         </> : ''}
                                        </div>
                                        {/* rating  */}

                                    </div>
                                </div>
                                <div className="flex flex-wrap gap-5 flex-row md:flex-col justify-between">
                                    <div className="flex xl:justify-end">
                                        <small className="text-xs">Stock: {product?.data?.stock}</small>
                                    </div>
                                    <div className="flex items-center gap-6">
                                        <div className="flex items-center border border-[rgb(216_216_216)] gap-5 md:gap-10 rounded-md py-2 px-3">
                                              <button className='text-gray-500' onClick={() => handleDecreaseCart(product?.data)} type="button">
                                                  <RemoveIcon  />
                                              </button>

                                            <p className="text-[rgb(15,132,17)]">{product?.cartQuantity}</p>

                                            {
                                              product?.data?.stock > product?.cartQuantity ?
                                              <button className='text-gray-500' onClick={() => handleAddToCart(product?.data)} type="button">
                                                  <AddIcon  />
                                              </button>
                                              :
                                              <button onClick={ ()=>handleWarning() } className='text-gray-400' type="button">
                                                  <AddIcon />
                                              </button>
                                            }

                                        </div>
                                        <div>
                                            <p className="font-semibold text-gray-500 text-sm">
                                            Total:
                                            {
                                              product?.data?.discount ? 
                                              <> {product?.data?.discount_type == 'percentage' ? <>{(decreaseByPercentage(product?.data?.price, product?.data?.discount) * product?.cartQuantity).toFixed(2)} BDT</> : <>{((product?.data?.price - product?.data?.discount) * product?.cartQuantity).toFixed(2)} BDT </>}</> :
                                              <>{(product?.data?.price * product?.cartQuantity).toFixed(2)} BDT</>
                                            }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          ))}
                              </>
                            )
                          }
                          
                          
                        </>

                        )}

                        
                    </div>
                    <div className="w-full xl:w-1/4">
                        <div className="border border-[rgb(161_232_135)] flex items-center justify-center gap-5 px-5 py-3 rounded-md">
                          <a href="/" className="text-sm hover:text-green-700 font-semibold">Continue Shopping</a>
                        </div>
                        <div className="mt-10 border border-[rgb(212_212_212)] px-5 py-4 rounded-md flex flex-col gap-5">
                            <h3 className="font-bold">Shopping Summary</h3>
                            <div className="flex items-center justify-between">
                                <p>Quantity</p>
                                <p className="font-bold">{cartData.cartTotalQuantity}</p>
                            </div>
                            <div className="flex items-center justify-between">
                                <p>Subtotal</p>
                                <p className="font-bold">{(cartData?.cartTotalAmount).toFixed(2)} BDT</p>
                            </div>
                            {
                              cartData?.cartItems?.length === 0 ? (
                                <button
                                    type="button"
                                    className="bg-[#564f56] cursor-move w-full px-2 py-2 rounded-md text-white"
                                    disabled
                                  >
                                    CheckOut
                                  </button>
                              ) : (
                                <button
                                  type="button"
                                  className="bg-[#493548] cursor-pointer w-full px-2 py-2 rounded-md text-white"
                                  onClick={() => navigate('/checkout')}
                                >
                                  Process to CheckOut
                                </button>
                              )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Cart;
