import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { setHeaders, url } from '../../../features/api';
import OnSale from '../../Shared/OnSale/OnSale';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import ProductDescription from '../ProductDescription/ProductDescription';
import ProductsDetails from '../ProductsDetails/ProductsDetails';

const ProductView = () => {
    const { slug } = useParams();

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errors, setError] = useState(null);
    const [del, setDel] = useState(1);

    useEffect(() => {
       axios.get(`${url}/products/product-view/${slug}`, setHeaders)
        .then(response => {
          setData(response?.data);
        })
        .catch(err => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }, [del, slug]);

    
    const breadcrumbs = [
        {
            id: 1,
            title: 'Home',
            product: `${data?.category?.name}`,
        },
    ];

    return (
      <>
            {breadcrumbs.map((bread) => (
                <Breadcrumbs bread={bread} key={bread.id} />
            ))}
            <ProductsDetails product={data} />
            <ProductDescription product={data} del={del} setDel={setDel} />
            <OnSale />
        </>
    );
};

export default ProductView;
